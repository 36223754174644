import {
  Endpoint,
  HttpMethod,
  RequestParams,
  performCRUDRequest
} from "@/services/userServices";

export interface Material {
  /** Should be a unique 28-bit sequence that never changes. Will not be visible to the user. */
  uuid: string;
  /** Short unique material title that will be shown in the user interface. */
  title: string;
  /** Values for the eight frequency bands 62.5, 125, 250, 500, 1000, 2000, 4000, 8000 Hz */
  values: readonly [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  /** Material description that will be shown in the material details. */
  description: string;
  /** Scientific reference/Source of the material data. */
  source: string;
  /** 1 = Default, 2 = Custom, 3 = Package */
  type: number;
  /** Sustainability metrics, representing the environmental impact across lifecycle phases. */
  sustainability: {
    /** Product Stage */
    product: number;
    /** Construction Stage */
    construction: number;
    /** Use Stage */
    use: number;
    /** End of Use Stage */
    endOfUse: number;
    /** Net benefits and Loads */
    netBenefitsAndLoads: number;
    source: string;
  } | null;
}

/** Interface that is used in POST API call. Used to add custom materials. */
export interface CreateMaterialRequest {
  title: string;
  values: readonly [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  description: string;
  source: string;
  uuid?: string;
}

export type MaterialDatabase = Map<Material["uuid"], Material>;

let ABSORPTION_MATERIALS: Material[] = [
  {
    uuid: "e2e2a8a7-4278-4c8f-92d8-302bc54f1bca",
    title: "NOTHING",
    description: "nichts",
    values: [0, 0, 0, 0, 0, 0, 0, 0],
    source: "src",
    type: 1,
    sustainability: null
  }
];
export let ABSORPTION_DATABASE: MaterialDatabase;

export function initMaterials(materialData: Material[]) {
  ABSORPTION_MATERIALS = materialData;
  ABSORPTION_DATABASE = new Map(ABSORPTION_MATERIALS.map(m => [m.uuid, m]));
}

/** POST */
export var MaterialDB = {
  async addMaterial(newMat: CreateMaterialRequest): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.POST,
        endpoint: Endpoint.Material,
        UUID: undefined,
        data: newMat
      };
      const response: Material = await performCRUDRequest<Material>(params);
      this.add_ABSORPTION_MATERIAL(response);
      return response;
    } catch (error) {
      console.error("Error saving new material:", error);
      throw error;
    }
  },
  add_ABSORPTION_MATERIAL(newMat: Material) {
    ABSORPTION_MATERIALS.push(newMat);
    ABSORPTION_DATABASE.set(newMat.uuid, newMat);
  },

  /** PUT */
  async updateMaterial(updateMat: any, uuid: any): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.PUT,
        endpoint: Endpoint.Material,
        UUID: uuid,
        data: updateMat
      };
      const response = await performCRUDRequest<Material>(params);
      ABSORPTION_DATABASE.set(uuid, updateMat);
      return response;
    } catch (error) {
      console.error("Error updating material", error);
      throw error;
    }
  },

  /** get the UUID of the previous material. Used to select previous material when one is deleted */
  getPreviousUUID: (currentUUID: string): string | null => {
    let previousUUID: string | null = null;
    for (const [uuid] of ABSORPTION_DATABASE) {
      if (uuid === currentUUID) {
        break;
      }
      previousUUID = uuid;
    }
    return previousUUID;
  },

  /** DELETE */
  async deleteMaterialByUUID(UUID: string): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.DELETE,
        endpoint: Endpoint.Material,
        UUID: UUID,
        data: undefined
      };
      const response: Material = await performCRUDRequest<Material>(params);
      this.remove_ABSORPTION_MATERIAL(response.uuid);
      return response;
    } catch (error) {
      console.error("Error removing material", error);
      throw error;
    }
  },
  remove_ABSORPTION_MATERIAL(UUID: string) {
    ABSORPTION_DATABASE.delete(UUID);
    ABSORPTION_MATERIALS = Array.from(ABSORPTION_DATABASE.values());
  }
};

export const FALLBACK_ABSORPTION_MATERIAL =
  "e2e2a8a7-4278-4c8f-92d8-302bc54f1bca";
export const FALLBACK_SCATTERING_MATERIAL =
  "5fa04f6d-81e8-4905-91e1-64a3a4779e8d";

/** Scattering */
let SCATTERING_MATERIALS: Material[] = [];
export var SCATTERING_DATABASE: MaterialDatabase;

export function initScattering(materialData: Material[]) {
  SCATTERING_MATERIALS = materialData;
  SCATTERING_DATABASE = new Map(SCATTERING_MATERIALS.map(m => [m.uuid, m]));
}

/** POST */
export var ScatteringDB = {
  async addScattering(newScat: CreateMaterialRequest): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.POST,
        endpoint: Endpoint.Scattering,
        UUID: undefined,
        data: newScat
      };
      const response: Material = await performCRUDRequest<Material>(params);
      this.add_SCATTERING_MATERIAL(response);
      return response;
    } catch (error) {
      console.error("Error saving new scattering material", error);
      throw error;
    }
  },
  add_SCATTERING_MATERIAL(newScat: Material) {
    SCATTERING_MATERIALS.push(newScat);
    SCATTERING_DATABASE.set(newScat.uuid, newScat);
  },

  /** PUT */
  async updateScattering(updateScat: any, uuid: any): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.PUT,
        endpoint: Endpoint.Scattering,
        UUID: uuid,
        data: updateScat
      };
      const response = await performCRUDRequest<Material>(params);
      SCATTERING_DATABASE.set(uuid, updateScat);
      return response;
    } catch (error) {
      console.error("Error updating scattering material", error);
      throw error;
    }
  },

  /** DELETE */
  async deleteScatteringByUUID(UUID: string): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.DELETE,
        endpoint: Endpoint.Scattering,
        UUID: UUID,
        data: undefined
      };
      const response: Material = await performCRUDRequest<Material>(params);
      this.remove_SCATTERING_MATERIAL(response.uuid);
      return response;
    } catch (error) {
      console.error("Error removing scattering material:", error);
      throw error;
    }
  },
  remove_SCATTERING_MATERIAL(UUID: string) {
    SCATTERING_DATABASE.delete(UUID);
    SCATTERING_MATERIALS = Array.from(SCATTERING_DATABASE.values());
  },

  /** get the UUID of the previous scattering material. Used to select previous scatterig material when one is deleted */
  getPreviousUUID: (currentUUID: string): string | null => {
    let previousUUID: string | null = null;
    for (const [uuid] of SCATTERING_DATABASE) {
      if (uuid === currentUUID) {
        break;
      }
      previousUUID = uuid;
    }
    return previousUUID;
  }
};
