<template>
  <div>
    <div v-if="materialsWithSustainability().length > 0">
      <p>
        The total contribution to climate change of the entered configuration
        is:
        {{ this.getEquivalentTotalSum() }} kg
      </p>
      <Bar
        :chart-options="chartOptions"
        :chart-data="individualChartData"
        :chart-id="'individual-chart'"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
      <br />
      <Bar
        :chart-options="chartOptions"
        :chart-data="summedChartData"
        :chart-id="'summed-chart'"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
      <br />
      <span v-if="materialsWithoutSustainability().length > 0">
        <p>
          The following materials are not included in the diagram because there
          are no LCA data available:
        </p>
        <ul>
          <li
            v-for="material in showAll
              ? materialsWithoutSustainability()
              : materialsWithoutSustainability().slice(0, 3)"
            :key="material.uuid"
          >
            {{ material.title }}
          </li>
        </ul>
        <span
          v-if="materialsWithoutSustainability().length > 3"
          @click="toggleShowAll"
          style="cursor: pointer; color: #1f82c0; margin-left: 1rem"
        >
          {{ showAll ? "show less..." : "show more..." }}
        </span>
      </span>
      <ExplanationHint title="Read more about this Life Cycle Assessment...">
        <p>
          The standardised life cycle assessment method (product-independent
          according to ISO 14040 and 14044, building-specific according to EN
          15804 and 15978) combines a life cycle view, technical process chain
          analysis and analysis of environmental impacts and is an optimal tool
          for analysing and evaluating the resource efficiency and environmental
          impacts of building components through to entire buildings and
          therefore provides a basis for deriving optimisations. These can be
          described using different impact categories, often represented by the
          global warming potential (GWP), i.e. the respective contribution to
          climate change. Depending on the scope of the study, different results
          (impact categories) of the life cycle assessment are evaluated and
          analysed.
        </p>
        <p>
          As the Fraunhofer Institute for Building Physics, we offer you
          wide-ranging services in all areas relating to life cycle assessment
          and sustainability. These include greenhouse gas reduction for
          companies, digital solutions for process-integrated sustainability,
          creation of corporate carbon footprints (CCF) and environmental
          product declarations (EPD). Furthermore we provide our own websoftware
          Generis<span style="font-size: 0.7em; vertical-align: super">®</span>
          for building LCA. For more information and detailed analysis of your
          own product, please feel free to contact
          <a href="mailto:michael.jaeger@ibp.fraunhofer.de" style="color: #fff"
            >michael.jaeger@ibp.fraunhofer.de</a
          >
        </p>
        <template>
          <div>
            <div v-if="hasZeroLifecyclePhase">
              <p>
                One or more life cycle stages are not displayed in the diagram
                because their values are 0.
              </p>
            </div>
          </div>
        </template>
      </ExplanationHint>
    </div>

    <div v-else>
      <p>
        The results for sustainability and the diagram are not displayed because
        no materials with life cycle data have been selected.
      </p>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js";
import { store } from "@/state/state";
import ExplanationHint from "@/components/ExplanationHint.vue";
import { ABSORPTION_DATABASE } from "@/res/materials-absorption";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
    ExplanationHint
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      lifecycleDataList: [],
      listWithoutLifecycleData: [],
      showAll: false,
      individualChartData: {
        labels: ["Materials"],
        datasets: this.generateIndividualSets()
      },
      summedChartData: {
        labels: ["Life Cycle"],
        datasets: this.generateSummedDatasets()
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "CO₂ Equivalent [kg]",
              align: "center",
              color: "#000000DE",
              font: {
                size: 16
              }
            },
            ticks: {
              color: "#000000DE",
              font: {
                size: 12
              },
              callback: function (value) {
                return Number(value).toFixed(0);
              }
            },
            grid: {
              color: "#000000DE",
              lineWidth: 1
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              align: "end",
              color: "#000000DE",
              font: {
                size: 12
              }
            },
            ticks: {
              color: "#000000DE",
              minRotation: 90,
              align: "end",
              font: {
                size: 16
              },
              labelOffset: -25
            },
            grid: {
              color: "#000000DE",
              lineWidth: 1
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              color: "#000000DE"
            }
          }
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10
          }
        }
      }
    };
  },
  methods: {
    retrieveMaterials() {
      const roomWalls = store.calc.roomWalls;

      const materialIDs = roomWalls
        .map(wall => wall.map(assignment => assignment.mat))
        .flat();

      const newList = materialIDs
        .map(id => {
          const material = ABSORPTION_DATABASE.get(id);
          if (material) {
            return {
              uuid: material.uuid,
              title: material.title,
              values: material.values,
              description: material.description,
              source: material.source,
              id: material.id,
              sustainability: material.sustainability || null
            };
          }
          return undefined;
        })
        .filter(material => material !== undefined);

      return newList;
    },
    materialsWithSustainability() {
      return this.retrieveMaterials().filter(
        material => material.sustainability
      );
    },
    materialsWithoutSustainability() {
      const seenTitles = [];
      return this.retrieveMaterials().filter(material => {
        if (!material.sustainability) {
          if (!seenTitles.includes(material.title)) {
            seenTitles.push(material.title);
            return true;
          }
        }
        return false;
      });
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    hasZeroLifecyclePhase() {
      return this.materialsWithSustainability.some(
        material =>
          material.sustainability.product === 0 ||
          material.sustainability.construction === 0 ||
          material.sustainability.use === 0 ||
          material.sustainability.endOfUse === 0 ||
          material.sustainability.netBenefitsAndLoads === 0
      );
    },

    summedRoomSize() {
      const roomSize = store.calc.roomSize;
      let sum = 0;
      roomSize.forEach(room => {
        sum += room;
      });
      return sum;
    },
    generateIndividualSets() {
      const materialChartPalette = [
        "#179c7d",
        "#005b7f",
        "#a6bbc8",
        "#1c3f52",
        "#d3c7ae",
        "#008598",
        "#39c1cd"
      ];

      return this.materialsWithSustainability().map((material, index) => ({
        label: material.title,
        backgroundColor:
          materialChartPalette[index % materialChartPalette.length],
        data: [
          this.summedRoomSize() *
            (material.sustainability.product +
              material.sustainability.construction +
              material.sustainability.use +
              material.sustainability.endOfUse +
              material.sustainability.netBenefitsAndLoads)
        ]
      }));
    },

    generateSummedDatasets() {
      let totalProduct = 0;
      let totalConstruction = 0;
      let totalUse = 0;
      let totalEndOfUse = 0;
      let totalNetBenefitsAndLoads = 0;

      const lifecycleChartPalette = [
        "#b2d235",
        "#fdb913",
        "#bb0056",
        "#7c154d",
        "#7c157d"
      ];

      this.materialsWithSustainability().forEach(material => {
        totalProduct += this.summedRoomSize() * material.sustainability.product;
        totalConstruction +=
          this.summedRoomSize() * material.sustainability.construction;
        totalUse += this.summedRoomSize() * material.sustainability.use;
        totalEndOfUse +=
          this.summedRoomSize() * material.sustainability.endOfUse;
        totalNetBenefitsAndLoads +=
          this.summedRoomSize() * material.sustainability.netBenefitsAndLoads;
      });

      return [
        {
          label: "Total Product Stage",
          backgroundColor: lifecycleChartPalette[0],
          data: [totalProduct]
        },
        {
          label: "Total Construction Stage",
          backgroundColor: lifecycleChartPalette[1],
          data: [totalConstruction]
        },
        {
          label: "Total Use Stage",
          backgroundColor: lifecycleChartPalette[2],
          data: [totalUse]
        },
        {
          label: "Total End of Use Stage",
          backgroundColor: lifecycleChartPalette[3],
          data: [totalEndOfUse]
        },
        {
          label: "Total Net Benefits and Loads",
          backgroundColor: lifecycleChartPalette[4],
          data: [totalNetBenefitsAndLoads]
        }
      ];
    },

    getEquivalentTotalSum() {
      let totalProduct = 0;
      let totalConstruction = 0;
      let totalUse = 0;
      let totalEndOfUse = 0;
      let totalNetBenefitsAndLoads = 0;

      this.materialsWithSustainability().forEach(material => {
        const roomSize = this.summedRoomSize();
        totalProduct += roomSize * material.sustainability.product;
        totalConstruction += roomSize * material.sustainability.construction;
        totalUse += roomSize * material.sustainability.use;
        totalEndOfUse += roomSize * material.sustainability.endOfUse;
        totalNetBenefitsAndLoads +=
          roomSize * material.sustainability.netBenefitsAndLoads;
      });

      const totalSum =
        totalProduct +
        totalConstruction +
        totalUse +
        totalEndOfUse +
        totalNetBenefitsAndLoads;

      return totalSum.toFixed(2);
    }
  }
};
</script>
<style scoped>
.leaf-icon {
  transform: scale(1.2);
  color: "gray";
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
}
</style>
